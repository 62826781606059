import "./index.scss";
import { defineComponent, inject } from "vue";
export default defineComponent({
    setup: function () {
        var coltd = inject("coltd");
        return {
            coltd: coltd,
        };
    },
});
