import { __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, ref } from "vue";
import router from "@/router/index";
import { posterApi } from "@/api/poster";
export default defineComponent({
    setup: function () {
        var _this = this;
        var navActiveIndex = ref("1");
        var posterList = ref("");
        var getPosterList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = posterList;
                        return [4 /*yield*/, posterApi({
                                posterType: "",
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var JumpProductDetails = function (item) {
            var productInfo = JSON.stringify(item);
            router.push({
                name: "productDetails",
                query: { productInfo: encodeURIComponent(productInfo) },
            });
        };
        onMounted(function () {
            getPosterList();
        });
        return {
            JumpProductDetails: JumpProductDetails,
            posterList: posterList,
            navActiveIndex: navActiveIndex,
        };
    },
});
